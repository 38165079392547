.editorArea {
  position: relative;
  flex: 1 1 auto;
  flex-basis: 100%;
  transition: opacity 0.3s;
  *::selection {
    background: #c1fa8b;
  }
}
.tagEditorCard {
  height: 100%;
  background-color: transparent;
  position: relative;
}
.editorWrapper {
  padding-right: 10px;
  height: 100%;
  position: relative;
}
.editor {
  font-size: 14px;
  height: 100%;
  line-height: 1.75;
}
.editorTagsSelector {
  flex-wrap: nowrap;
}
.popoverTagsSelector {
  right: 0;
  top: 0;
  bottom: 0;
  left: unset !important;
}
.tagsInContext {
  flex-shrink: 0;
  padding: 5px;
  label {
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 18px;
  }
  small {
    font-size: 12px;
    color: var(--dark-grey);
    display: inline-block;
    max-height: 150px;
    overflow-y: scroll;
    text-overflow: ellipsis;
    width: 100%;
    height: fit-content;
    white-space: pre-line;
    font-style: italic;
  }
  > div > div > div {
    text-align: left !important;
  }
}

.tagEditorFooter {
  margin: 0;
  padding: 4px;
  height: 46px;
  min-height: 46px;
  max-height: 46px;
  overflow: hidden;
  border-top: 1px solid var(--grey);
}
